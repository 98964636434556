import { Component, OnInit, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ReminderComponent } from '../../dialogs/reminder/reminder/reminder.component';
import { ContactsComponent } from '../../dialogs/contact/contact.component';
import { componentFactoryName } from '@angular/compiler';
import { EditProfileComponent } from '../../dialogs/edit-profile/edit-profile.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchedulingComponent } from '../../dialogs/scheduling/scheduling.component';
import { DeleteConfirmComponent } from '../../dialogs/delete-confirm/delete-confirm.component';

import _ from 'lodash';
import { firestore } from 'firebase';
import { InfoDialogComponent } from '../../dialogs/info-dialog/info-dialog.component';
import { environment } from '../../../../environments/environment';


export interface User {
  firstName: string;
  infoUserActive: boolean;
  infoContactActive: boolean;
  infoControleActive: boolean;
  infoScheduleActive: boolean;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
  contacts: Contact[];
  reminder: string;
  id: string;
  plan: {
    type: string;
    validUntil: Date | firestore.Timestamp;
  };
}

export interface Contact {
  name: string;
  delay: number;
  phone: string;
  validated: boolean;
  inviteOpen: boolean;
  deniedInvitation: boolean;
}
export interface ContactId extends Contact { id: string; }

export interface Reminder {
  id: string;
  name: string;
  delay: number;
  order: number;
}
export interface ReminderId extends Reminder { id: string; }

export interface Schedule {
  name: string;
  repeat: boolean;
  time: Timestamp;
  responseRequired: boolean;
  active: boolean;
}
export interface ScheduleId extends Schedule { id: string; }

export interface Tags {
  ref: string;
  tags: {
    preference: boolean;
    ref: string
  };
}

export interface DialogData {
  animal: string;
  name: string;
}

export interface Tags {
  ref: string;
  tags: {
    preference: boolean;
    ref: string
  };
}

@Component({
  selector: 'app-profile-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss', '../../profile.component.scss']
})
export class AboutComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userTags: AngularFirestoreDocument<Tags>;
  user: Observable<User>;
  userData: User;

  userId: string; // @Input
  qrCode: string = null;
  showChipEdit = false;
  community: Observable<Tags>;

  mobileDevice: boolean;
  // TAGS
  tagControl = new FormControl();
  tags: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  yesTags: string[] = ['testing', 'miense'];
  noTags: string[] = [];

  remindersCollection: AngularFirestoreCollection<Reminder>;
  reminders: Observable<ReminderId[]>;

  schedulesCollection: AngularFirestoreCollection<Schedule>;
  schedules: Observable<ScheduleId[]>;
  orderedSchedules: [];

  contactsCollection: AngularFirestoreCollection<Contact>;
  contacts: Observable<ContactId[]>;

  constructor(
    private route: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore, public dialog: MatDialog,
    private _snackBar: MatSnackBar) {
    this.determineDevice();
    this.qrCode = 'Your QR code data string';
    this.user = new Observable();
    this.reminders = new Observable();
    this.schedules = new Observable();
    // this.userId = new Observable();
    const currentUser = this.afAuth.auth.currentUser;
    if (currentUser) {
      this.userId = currentUser.uid;
      this.userDoc = afs.doc<User>('users/' + currentUser.uid);
      this.remindersCollection = afs.collection<Reminder>('users/' + currentUser.uid + '/reminders');
      this.schedulesCollection = afs.collection<Schedule>('users/' + currentUser.uid + '/scheduleItems');
      this.contactsCollection = afs.collection<Contact>('users/' + currentUser.uid + '/contacts', ref => ref.orderBy('delay'));
      this.user = this.userDoc.valueChanges().pipe(map(userData => {
        // console.log('User =>', userData);
        if (!userData) {
          userData = null;
          return;
        }
        // if (userData.contacts) {
        //   this.contacts = _.sortBy(userData.contacts, 'delay');
        // } else {
        //   this.contacts = [];
        // }
        // console.log('contacts', this.contacts);
        userData = userData;
        return userData;
      }));
      this.user.subscribe(userData => {
        console.log('userData => ', userData);
        this.userData = userData;
      });

      this.contacts = this.contactsCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Contact;
        const id = a.payload.doc.id;
        // console.log('reminders = >', id, data);
        return { id, ...data };
      })));
      this.contacts.subscribe(contacts => {
        // console.log('Schedule = >', schedule);
        // this.orderedSchedules = _.sortBy(schedule, 'time');
        console.log('contacts', contacts);
      });

      this.reminders = this.remindersCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Reminder;
        const id = a.payload.doc.id;
        // console.log('reminders = >', id, data);
        return { id, ...data };
      })));
      this.reminders.subscribe(reminder => {
        // console.log('Schedule = >', schedule);
        // this.orderedSchedules = _.sortBy(schedule, 'time');
        console.log('reminders', reminder);
      });
      this.schedules = this.schedulesCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Schedule;
        const id = a.payload.doc.id;
        // console.log('test = >', test);
        return { id, ...data };
      })));
      this.schedules.subscribe(schedule => {
        // console.log('Schedule = >', schedule);
        this.orderedSchedules = _.sortBy(schedule, 'time');
        console.log('orderedSchedules', this.orderedSchedules);
      });
      console.log('userId =>', this.userId);
    } else {
      // not signed in
      this.route.navigate(['/profile']);
    }
  }

  ngOnInit() {
  }

  toggle() {
    this.showChipEdit = true;
  }

  closeChips() {
    console.log('closing chips');
    this.showChipEdit = false;
  }

  // edit reminders
  editReminder(reminderId, reminderName, reminderTime, infoControleActive): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ReminderComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: {
          userId: this.userId, editReminderId: reminderId, editReminderName:
            reminderName, editReminderTime: reminderTime, infoControleActive
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ReminderComponent, {
        width: '500px',
        // height: '200px',
        data: {
          userId: this.userId, editReminderId: reminderId, editReminderName: reminderName,
          editReminderTime: reminderTime, infoControleActive
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  deleteReminder() {

  }
  // add, edit and delete contacts
  addContacts(infoContactActive?): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ContactsComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, infoContactActive }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result === 'saved') {
          console.log('this.userData.plan', this.userData.plan);
          if (!this.userData.plan) {
            const validUntilDate = new Date();
            validUntilDate.setDate(validUntilDate.getDate() + 31);
            validUntilDate.setHours(23, 59, 59, 0);
            const plan = {
              validUntil: validUntilDate,
              type: 'free',
            };
            this.userDoc.ref.set({ plan }, {
              merge: true
            });
          }
        }
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(ContactsComponent, {
        width: '500px',
        // height: '200px',
        data: { userId: this.userId, infoContactActive }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result === 'saved') {
          console.log('this.userData.plan', this.userData.plan);
          if (!this.userData.plan) {
            const validUntilDate = new Date();
            validUntilDate.setDate(validUntilDate.getDate() + 31);
            validUntilDate.setHours(23, 59, 59, 0);
            const plan = {
              validUntil: validUntilDate,
              type: 'free',
            };
            this.userDoc.ref.set({ plan }, {
              merge: true
            });
          }
        }
      });
    }
  }

  editContacts(contacts, contact, user): void {
    const foundContact = contacts.find(contactFind => contactFind.phone == contact.phone);
    console.log('foundContact:', foundContact)
    if (this.mobileDevice) {
      const dialogRef = this.dialog.open(ContactsComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: {
          userId: this.userId, editContact: true, contact: contact, user
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      const dialogRef = this.dialog.open(ContactsComponent, {
        width: '500px',
        // height: '200px',
        data: {
          userId: this.userId, editContact: true, contact: contact, user
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  async deleteContact(contact) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: {
        title: 'contactpersoon',
        name: contact.name,
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('lets delete this user', contact);
        const contactDoc = this.afs.doc('users/' + this.userId).collection('contacts').doc(contact.phone);
        await contactDoc.delete();
        this._snackBar.open('Contactpersoon verwijderd', 'X', {
          duration: 5000,
        });
      }
    });
  }

  editProfile(firstName, phone, infoUserActive) {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(EditProfileComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, userName: firstName, userPhone: phone, infoUserActive }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(EditProfileComponent, {
        width: '500px',
        // height: '200px',
        data: { userId: this.userId, userName: firstName, userPhone: phone, infoUserActive }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  addSchedule(infoScheduleActive, firstName): void {
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, infoScheduleActive, userName: firstName }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        width: '650px',
        // height: '200px',
        data: { userId: this.userId, infoScheduleActive, userName: firstName }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  editSchedule(id, firstName, time, name, type, repeat, responseRequired, infoScheduleActive, message, customMessage, active): void {
    console.log('firstName', firstName);
    if (this.mobileDevice) {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: {
          userId: this.userId, editId: id, userName: firstName,
          editTime: time, editName: name, editType: type, editRepeat: repeat,
          editResponseRequired: responseRequired, infoScheduleActive,
          editMessage: message, editCustomMessage: customMessage,
          editactive: active
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    } else {
      console.log('open dialog');
      const dialogRef = this.dialog.open(SchedulingComponent, {
        width: '650px',
        // height: '200px',
        data: {
          userId: this.userId, editId: id, userName: firstName,
          editTime: time, editName: name, editType: type, editRepeat: repeat,
          editResponseRequired: responseRequired, infoScheduleActive,
          editMessage: message, editCustomMessage: customMessage,
          editactive: active
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }

  async deleteSchedule(scheduleName, Id) {
    // open confirm screen
    // console.log(title);
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: {
        title: 'alarm',
        name: scheduleName,
      }
    });
    // delete data if value is true
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log(result);
        console.log('id', Id);
        const scheduleDoc = this.afs.doc<Schedule>('users/' + this.userId + '/scheduleItems/' + Id);
        await scheduleDoc.delete();
        console.log('document deleted');
      }
    });
  }

  updateState(state, Id) {
    console.log('change state => ', state);
    const scheduleDoc = this.afs.doc('users/' + this.userId + '/scheduleItems/' + Id);
    scheduleDoc.update({ active: state.checked });
  }

  openInfoDialog() {
    let infoText = 'Controleberichten worden verstuurd op het tijdstip dat er een geplande melding is. Je kunt hier instellen na hoeveel minuten niet reageren er een eerste herinnering, daarna een 2de herinnering en vervolgens een melding naar je contactpersonen gaat.';
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '650px',
      data: {
        title: 'Herinneringen inrichten informatie',
        name: infoText,
      }
    });
    // delete data if value is true
    dialogRef.afterClosed().subscribe(async result => {

    });
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
