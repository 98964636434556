import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Observable, combineLatest, ReplaySubject } from 'rxjs';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { FormControl } from '@angular/forms';

export interface Organisation {
  name: string;
}
export interface OrganisationTag {
  id: string;
  name: string;
  color: string;
  preference: boolean;
}
export interface OrganisationUser {
  id: string;
  name: string;
}
export interface OrganisationUserTag {
  id: string;
  preference: boolean;
}
@Component({
  selector: 'app-profile-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss', '../../profile.component.scss']
})
export class OrganisationComponent implements OnInit {
  @Input() organisationId;
  organisation: Observable<Organisation>;
  organisationDoc: AngularFirestoreDocument<Organisation>;
  organisationTags: Observable<OrganisationTag[]>;
  organisationTagsCollection: AngularFirestoreCollection<OrganisationTag>;

  organisationUser: Observable<OrganisationUser>;
  organisationUserDoc: AngularFirestoreDocument<OrganisationUser>;
  organisationUserTags: Observable<OrganisationUserTag[]>;
  organisationUserTagsCollection: AngularFirestoreCollection<OrganisationUserTag>;

  public allTags: ReplaySubject<OrganisationTag[]> = new ReplaySubject<OrganisationTag[]>(1);
  public preferenceYesTags: ReplaySubject<OrganisationTag[]> = new ReplaySubject<OrganisationTag[]>(1);
  public preferenceNoTags: ReplaySubject<OrganisationTag[]> = new ReplaySubject<OrganisationTag[]>(1);

  // Everything to do with making tags editable
  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  selectedYesTags = [];
  selectedYesTagsBackup = [];
  selectedNoTags = [];
  selectedNoTagsBackup = [];
  editYesTags = false;
  editNoTags = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: ReplaySubject<OrganisationTag[]> = new ReplaySubject<OrganisationTag[]>(1);

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    const currentUser = this.afAuth.auth.currentUser;
    console.log('organisationId', this.organisationId);
    this.organisationDoc = this.db.doc<Organisation>('organisations/' + this.organisationId);
    this.organisationTagsCollection = this.organisationDoc.collection<OrganisationTag>('tags');

    this.organisationUserDoc = this.db.doc<OrganisationUser>('organisations/' + this.organisationId + '/volunteers/' + currentUser.uid);
    this.organisationUserTagsCollection = this.organisationUserDoc.collection<OrganisationUserTag>('tags');

    this.organisation = this.organisationDoc.valueChanges().pipe(map(organisation => {
      return organisation;
    }), take(1));
    this.organisationTags = this.organisationTagsCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
      const id = a.payload.doc.id;
      const data = a.payload.doc.data() as object;
      data['id'] = id;
      const fullData = data as OrganisationTag;
      return fullData;
    })), take(1));

    this.organisationUser = this.organisationUserDoc.valueChanges();
    this.organisationUserTags = this.organisationUserTagsCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
      const id = a.payload.doc.id;
      const data = a.payload.doc.data() as object;
      data['id'] = id;
      const fullData = data as OrganisationUserTag;
      return fullData;
    })));

    const combinedTags = combineLatest(
      this.organisationTags,
      this.organisationUserTags,
    );
    combinedTags.pipe().subscribe(values => {
      console.log('combinedTags values', values);
      const allTags = [];
      const yesTags = [];
      const noTags = [];
      const tags = values[0].map(x => Object.assign({}, x));
      const userTags = values[1];
      console.log('tags', tags);
      console.log('userTags', userTags);
      tags.forEach(tag => {
        const matchingTag = userTags.find((userTag) => {
          return userTag.id === tag.id;
        });
        if (matchingTag) { tag.preference = matchingTag.preference; }
        if (tag.preference === true) { yesTags.push(tag); }
        if (tag.preference === false) { noTags.push(tag); }
        allTags.push(tag);
      });
      console.log('allTags', allTags);
      console.log('yesTags', yesTags);
      console.log('noTags', noTags);
      this.allTags.next(allTags);
      // cloneDeep alternative
      this.selectedYesTags = yesTags.map(x => Object.assign({}, x));
      this.selectedYesTagsBackup = yesTags;
      this.selectedNoTags = noTags.map(x => Object.assign({}, x));
      this.selectedNoTagsBackup = noTags;
    });

    const combinedFilter = combineLatest(
      this.tagCtrl.valueChanges,
      this.allTags,
    );
    combinedFilter.pipe(debounceTime(300), distinctUntilChanged()).subscribe(values => {
      console.log('combinedFilter values', values);
      const filteredTags = [];
      const input = values[0];
      const allTags = values[1];
      console.log('allTags', allTags);
      allTags.forEach(tag => {
        const tagName = tag.name.toLowerCase();
        if (tagName.includes(input)) {
          filteredTags.push(tag);
        }
      });

      console.log('filteredTags', filteredTags);
      this.filteredTags.next(filteredTags);
      // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
    });
    this.tagCtrl.setValue('');

    this.organisation.subscribe(organisation => {
      console.log('organisation', organisation);
    });
    // this.organisationTags.subscribe(organisationTags => {
    //   console.log('organisationTags', organisationTags);
    // });

    this.organisationUser.subscribe(organisationUser => {
      console.log('organisationUser', organisationUser);
    });
    // this.organisationUserTags.subscribe(organisationUserTags => {
    //   console.log('organisationUserTags', organisationUserTags);
    // });
  }

  async savePreferenceTags(type) {
    let preferenceArray;
    let backupArray;
    let preference;
    if (type === 'yes') {
      preference = true;
      preferenceArray = this.selectedYesTags;
      backupArray = this.selectedYesTagsBackup.map(x => Object.assign({}, x));
    } else if (type === 'no') {
      preference = false;
      preferenceArray = this.selectedNoTags;
      backupArray = this.selectedNoTagsBackup.map(x => Object.assign({}, x));
    }
    console.log('preferenceArray', preferenceArray);
    const batch = this.db.firestore.batch();
    preferenceArray.forEach(tag => {
      const matchingTag = backupArray.find((backupTag) => {
        return backupTag.id === tag.id;
      });
      if (matchingTag) {
        matchingTag.stillExists = true;
        console.log('tag stillExists', tag);
      } else {
        batch.set(this.organisationUserTagsCollection.doc(tag.id).ref, {
          preference
        }, { merge: true });
      }
    });
    console.log('backupArray', backupArray);
    backupArray.forEach(tag => {
      console.log('tag', tag);
      if (!tag.stillExists) {
        batch.delete(this.organisationUserTagsCollection.doc(tag.id).ref);
      }
    });
    await batch.commit();
    if (type === 'yes') {
      this.editYesTags = false;
    } else if (type === 'no') {
      this.editNoTags = false;
    }
  }

  editPreferenceTags(type) {
    console.log('edit type', type);
    if (type === 'yes') {
      if (!this.editYesTags) {
        this.editYesTags = true;
      } else {
        this.editYesTags = false;
        this.selectedYesTags = this.selectedYesTagsBackup.map(x => Object.assign({}, x));
      }
    }
    if (type === 'no') {
      if (!this.editNoTags) {
        this.editNoTags = true;
      } else {
        this.editNoTags = false;
        this.selectedNoTags = this.selectedNoTagsBackup.map(x => Object.assign({}, x));
      }
    }
  }

  selectTag(type: string, event: MatAutocompleteSelectedEvent): void {
    let preference;
    let preferenceArray;
    this.tagCtrl.setValue('');
    this.tagInput.nativeElement.value = '';
    if (type === 'yes') {
      preference = true;
      preferenceArray = this.selectedYesTags;
    } else if (type === 'no') {
      preference = false;
      preferenceArray = this.selectedNoTags;
    }
    console.log('add type', type);
    const tag = event.option.value as OrganisationTag;
    console.log('tag', tag);
    if (tag.preference !== preference) {
      preferenceArray.push(tag);
    }
  }

  removeTag(type: string, tag: OrganisationTag): void {
    // this.selectedTags.push(event.option.viewValue);
    console.log('remove type', type);
    console.log('tag', tag);
    if (type === 'yes') {
      const index = this.selectedYesTags.indexOf(tag);
      this.selectedYesTags.splice(index, 1);
    } else if (type === 'no') {
      const index = this.selectedNoTags.indexOf(tag);
      this.selectedNoTags.splice(index, 1);
      // this.selectedNoTags.push(value);
    }
  }
}
