import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Timestamp } from '@firebase/firestore-types';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// import { FirstTimeDialogComponent } from '../profile/dialogs/first-time-dialog/first-time-dialog.component';
import { EditProfileComponent } from './dialogs/edit-profile/edit-profile.component';
import { ContactsComponent } from './dialogs/contact/contact.component';
import { firestore } from 'firebase';

export interface User {
  firstName: string;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
  timezone: string;
  contacts: [];
  reminder: string;
  id: string;
  userPhone: string;
  plan: {
    type: string;
    validUntil: firestore.Timestamp | Date;
  };
}

export interface Organisation {
  id: string;
  name: string;
}

export interface Reminder {
  id: string;
  name: string;
  time: number;
  order: number;
}
export interface ReminderId extends Reminder { id: string; }

export interface ScheduleItem {
  name: string;
  repeat: boolean;
  startTime: Timestamp;
}
export interface ScheduleItemId extends ScheduleItem { id: string; }

export interface Contact {
  name: string;
  delay: number;
  phone: string;
  validated: boolean;
  inviteOpen: boolean;
  deniedInvitation: boolean;
}
export interface ContactId extends Contact { id: string; }
export interface Tags {
  ref: string;
  tags: {
    preference: boolean;
    ref: string
  };
}

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  env = environment;
  // USER RELATED
  userDoc: AngularFirestoreDocument<User>;
  userTags: AngularFirestoreDocument<Tags>;
  user: Observable<User>;
  userData: User;
  userPhone: string;
  userName: string;
  userId: string; // @Input
  showChipEdit: boolean;
  community: Observable<Tags>;

  organisationsCollection: AngularFirestoreCollection<Organisation>;
  organisations: Observable<Organisation[]>;

  remindersCollection: AngularFirestoreCollection<Reminder>;
  reminders: Observable<ReminderId[]>;

  scheduleCollection: AngularFirestoreCollection<ScheduleItem>;
  scheduleItems: Observable<ScheduleItemId[]>;

  contactsCollection: AngularFirestoreCollection<Contact>;
  contacts: Observable<ContactId[]>;
  // QR code
  qrCode: string = null;
  mobileDevice: boolean;

  name: string = environment.name;
  constructor(private route: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore, public dialog: MatDialog) {
    console.log('TEST run1');
    console.log('environment', environment);
    console.log('this.env', this.env);
    this.determineDevice();
    this.qrCode = 'Your QR code data string';
    this.user = new Observable();
    this.reminders = new Observable();
    this.scheduleItems = new Observable();
    // this.userId = new Observable();

  }
  async ngOnInit() {
    const currentUser = this.afAuth.auth.currentUser;

    if (currentUser) {
      this.userId = currentUser.uid;
      this.userPhone = currentUser.phoneNumber;
      console.log('userlogin =>', currentUser);
      this.userDoc = this.afs.doc<User>('users/' + currentUser.uid);
      this.organisationsCollection = this.userDoc.collection<Organisation>('organisations', ref => ref.orderBy('name'));
      this.scheduleCollection = this.userDoc.collection<ScheduleItem>('scheduleItems', ref => ref.where('type', '==', 'reminder'));
      this.remindersCollection = this.userDoc.collection<Reminder>('reminders');
      this.contactsCollection = this.userDoc.collection<Contact>('contacts');
      this.user = this.userDoc.valueChanges().pipe(map(user => {
        console.log('User =>', user);

        return user;
      }), take(1));
      console.log(currentUser);
      // check if user has the required data for the application

      this.user.subscribe(async userData => {
        console.log('user =>', userData);
        this.userData = userData;

        if (userData === undefined || !userData.firstName) {
          // new user
          const userDoc = this.afs.doc('users/' + this.userId);
          userDoc.set({
            phone: this.userPhone
          }, { merge: true });
          let dialogOpt: any = {
            width: '500px',
            disableClose: true,
            data: { userId: this.userId, userName: '', userPhone: currentUser.phoneNumber, infoUserActive: false, firstTime: true }
          };
          if (this.mobileDevice) {
            dialogOpt = {
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '100%',
              width: '100%',
              disableClose: true,
              panelClass: 'app-full-screen-dialog',
              data: { userId: this.userId, userName: '', userPhone: currentUser.phoneNumber, infoUserActive: false, firstTime: true }
            };
          }
          const dialogRef = this.dialog.open(EditProfileComponent, dialogOpt);
          dialogRef.afterClosed().subscribe(() => {
            if (this.env.env !== 'bob') {
              this.checkContacts();
            }
          });

          const scheduleEmpty = await this.scheduleCollection.ref.limit(1).get().then(scheduleItems => {
            return scheduleItems.empty;
          });
          console.log('scheduleEmpty', scheduleEmpty);
          if (scheduleEmpty && this.env.env === 'helperapp') {
            this.createDefaultScheduleItems(this.userDoc);
          }

          const remindersEmpty = await this.remindersCollection.ref.limit(1).get().then(scheduleItems => {
            return scheduleItems.empty;
          });
          console.log('remindersEmpty', remindersEmpty);
          if (remindersEmpty) {
            this.createDefaultReminders(this.userDoc);
          }
        } else {
          if (this.env.env !== 'bob') {
            this.checkContacts();
          }
        }
      });
      this.organisations = this.organisationsCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
        const id = a.payload.doc.id;
        const data = a.payload.doc.data() as object;
        data['id'] = id;
        const organisationData = data as Organisation;
        return { id, ...organisationData };
      })));
    }
  }

  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      console.log('mobile device detected', navigator.userAgent);
      this.mobileDevice = true;
    } else {
      console.log('none mobile device detected');
      this.mobileDevice = false;
    }
    console.log('this.mobileDevice', this.mobileDevice);
  }
  async checkContacts() {
    const contactsEmpty = await this.contactsCollection.ref.limit(1).get().then(contacts => {
      return contacts.empty;
    });
    console.log('contactsEmpty', contactsEmpty);
    if (contactsEmpty) {
      this.addContacts(true);
    }
  }

  async createDefaultScheduleItems(userDoc) {
    const defaultScheduleItem = {
      active: true,
      responseRequired: true,
      useCustomMessage: true,
      repeat: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      hour: 9,
      minute: 0,
      time: new Date(2020, 0, 5, 9, 0, 0, 0), // 5 jan 2020
      name: 'Alarm 9:00',
      message: 'Gaat het goed met je?'
    };
    const promises = [];
    promises.push(userDoc.collection('scheduleItems').add(defaultScheduleItem));
    //
    defaultScheduleItem.hour = 15;
    defaultScheduleItem.time.setHours(15);
    defaultScheduleItem.name = 'Alarm 15:00';
    promises.push(userDoc.collection('scheduleItems').add(defaultScheduleItem));
    //
    defaultScheduleItem.hour = 21;
    defaultScheduleItem.time.setHours(21);
    defaultScheduleItem.name = 'Alarm 21:00';
    promises.push(userDoc.collection('scheduleItems').add(defaultScheduleItem));
    try {
      await Promise.all(promises);
    } catch (err) {
      return console.error(err);
    }
    console.log('default reminders created');
  }
  async createDefaultReminders(userDoc) {
    const promises = [];
    promises.push(userDoc.collection('reminders').doc('1').set({
      action: 'remind',
      name: '1e herinnering',
      order: 1,
      delay: 10
    }));
    promises.push(userDoc.collection('reminders').doc('2').set({
      action: 'remind',
      name: '2e herinnering',
      order: 2,
      delay: 10
    }));
    promises.push(userDoc.collection('reminders').doc('3').set({
      action: 'createCheckupGroup',
      name: 'melden',
      order: 3,
      delay: 10
    }));
    try {
      await Promise.all(promises);
    } catch (err) {
      return console.error(err);
    }
    console.log('default reminders created');
  }

  addContacts(infoContactActive?): void {
    let dialogOpt: any = {
      width: '500px',
      data: { userId: this.userId, infoContactActive }
    };
    if (this.mobileDevice) {
      dialogOpt = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'app-full-screen-dialog',
        data: { userId: this.userId, infoContactActive }
      };
    }

    const dialogRef = this.dialog.open(ContactsComponent, dialogOpt);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result === 'saved') {
        if (!this.userData || !this.userData.plan) {
          const validUntilDate = new Date();
          validUntilDate.setDate(validUntilDate.getDate() + 31);
          validUntilDate.setHours(23, 59, 59, 0);
          const plan = {
            validUntil: validUntilDate,
            type: 'free',
          };
          this.userDoc.ref.set({ plan }, {
            merge: true
          });
        }
      }
    });
  }

}
