import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from '../../../../profile/profile.component';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Timestamp } from '@firebase/firestore-types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';

export interface Minute {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss']
})
export class ReminderComponent implements OnInit {
  userId: string;
  reminderId: string;
  reminderName: string;
  reminderTime: number;

  numberControl: FormControl;
  panelOpenState: boolean;

  mobileDevice: boolean;

  constructor(public dialogRef: MatDialogRef<ReminderComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public afs: AngularFirestore, private _snackBar: MatSnackBar) {
    this.determineDevice();
    this.numberControl = new FormControl('', [Validators.max(100), Validators.min(0)]);
    this.userId = data.userId;
    this.reminderId = data.editReminderId;
    this.reminderName = data.editReminderName;
    this.reminderTime = data.editReminderTime;
    if (data.infoControleActive === undefined) {
      this.panelOpenState = true;
    } else {
      this.panelOpenState = data.infoControleActive;
    }
    console.log('panelOpenState', this.panelOpenState);
  }

  ngOnInit() {
  }

  editReminder(reminderTime) {
    console.log('userId =>', this.userId);
    console.log('reminderId =>', this.reminderId);
    console.log('reminder time =>', reminderTime);
    const reminderDoc = this.afs.doc('users/' + this.userId + '/reminders/' + this.reminderId);
    reminderDoc.update({
      delay: reminderTime
    }), {merge: true};
    this.dialogRef.close();
    this._snackBar.open('Herinnering aangepast', 'X', {
      duration: 5000,
    });
  }
  changeState() {
    const userDoc = this.afs.doc('users/' + this.userId);
    if (this.panelOpenState) {
      this.panelOpenState = false;
      userDoc.update({
        infoControleActive: this.panelOpenState
      }), {merge: true};
    } else if (!this.panelOpenState) {
      this.panelOpenState = true;
      userDoc.update({
        infoControleActive: this.panelOpenState
      }), {merge: true};
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  determineDevice() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
          console.log('mobile device detected', navigator.userAgent);
          this.mobileDevice = true;
     } else {
        console.log('none mobile device detected');
        this.mobileDevice = false;
     }
    console.log('this.mobileDevice', this.mobileDevice);
  }
}
