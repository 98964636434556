import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo } from '@angular/fire/auth-guard';
// import { SelectEntityComponent } from './select-entity/select-entity.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { ProfileComponent } from './profile/profile.component';

import { AppComponent } from './app.component';
import { SelectEntityComponent } from './select-entity/select-entity.component';
import { environment } from 'src/environments/environment';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToProfile = () => redirectLoggedInTo(['profile']);

let routes: Routes = [];
const allRoutes = {
  login: {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToProfile },
  },
  registration: {
    path: 'registration',
    component: RegistrationComponent
  },
  profile: {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  selectEntity: {
    path: 'select-entity',
    component: SelectEntityComponent
  },
};
console.log('allroutes', allRoutes);
// environment.routes.forEach(route => {
//   routes.push(allRoutes[route]);
// });
// routes.push({
//   path: '**',
//   redirectTo: 'profile',
//   canActivate: [AngularFireAuthGuard],
//   data: { authGuardPipe: redirectUnauthorizedToLogin },
// });

console.log('routes', routes);

const routes2: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'select-entity',
    component: SelectEntityComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
console.log('routes2', routes2);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    environment.routes.forEach(route => {
      config.push(allRoutes[route]);
    });
    config.push({
      path: '**',
      redirectTo: 'profile',
      canActivate: [AngularFireAuthGuard],
      data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
    console.log('router3', router);
  }
}
