import { Component, OnInit, Injectable } from '@angular/core';
// import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';

import { environment } from '../../environments/environment';
import { DeleteConfirmComponent } from '../profile/dialogs/delete-confirm/delete-confirm.component';

export interface User {
  firstName: string;
  phone: string;
  postal: number;
  address: string;
  ICEnumber: number;
  birthdate: Timestamp;
}
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  userId: string;
  fixed = true;
  select_entity: boolean;
  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches)
  //   );

  constructor(private route: Router, public afAuth: AngularFireAuth, public afs: AngularFirestore, public dialog: MatDialog) {
    this.afAuth.user.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.userDoc = afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(res => console.log('UserData =>', res));
        console.log('environment.name', environment.name);
        if (environment.name === 'Bob de Bot') {
          this.select_entity = true;
        } else {
          this.select_entity = false;
        }
        console.log('this.select', this.select_entity);
        // waiting for select entity page
        // this.userTags = afs.doc<Tags>('users/' + user.uid + '/communities' + '/exampleCommunity');
        // this.community = this.userTags.valueChanges();
        // console.log('this.community',this.community);
      }
    });
  }

  async logout() {
    console.log('User pressed button');
    await this.afAuth.auth.signOut();
    return this.route.navigate(['/login']);
  }

  async deleteUser() {
    console.log('function triggered')

    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: {
        title: 'account',
        name: 'je account',
      }
    });
    // delete data if value is true
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('result', result);
        const deleteUser = { status: 'notDeleted' };
        await this.afs.doc('deleteUserQueue/' + this.userId).set(deleteUser);
        await this.afAuth.auth.signOut();
        return this.route.navigate(['/login']);
      }
    });
  }

  // logout() {
  //   console.log("user logged out");
  //   this.afAuth.auth.signOut();
  // }

  ngOnInit() {
    // this.afAuth.auth.onAuthStateChanged(function(user) {
    //   if (user) {
    //     console.log("user =>", user.email)
    //     this.checkUser = true;
    //   } else {
    //     // User is signed out.
    //     // ...
    //   }
    // });
  }

}
