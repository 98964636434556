import { Directive, ElementRef, Renderer, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements OnInit {

  private _autofocus;
  constructor(private el: ElementRef, private renderer: Renderer)
  {
  }

  ngOnInit() {
    // if (this._autofocus || typeof this._autofocus === 'undefined') {
    //   this.el.nativeElement.focus();
    // }
  }

  ngAfterViewInit() {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      window.setTimeout(() => {
        // this.el.nativeElement.focus();
        this.renderer.invokeElementMethod(this.el.nativeElement, 'focus', []);
      });
    }
  }

  @Input() set autofocus(condition: boolean) {
    console.log('condition', condition);
    this._autofocus = condition !== false;
  }

}
